import AccordionCompo from "@/components/AccordionCompo";
import AlertBox from "@/components/AlertBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { MdEditSquare } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AddClient() {
  const { register, handleSubmit } = useForm();
  const logoref = useRef();
  const [clientId, setClientId] = useState("");
  const [clientData, setClientData] = useState({});
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.auth?.userData);
  const navigate = useNavigate()
  // const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  // if(!isAuthenticated) {
  //   return navigate("/")
  // }

  async function addClint(data) {
    data = { ...data, userId: userData?._id };
    
    if (
      data?.name?.length === 0 ||
      data?.industryType?.length === 0 ||
      data?.phoneNumber?.length === 0 ||
      data?.country?.length === 0 ||
      data?.state?.length === 0 ||
      data?.pincode?.length === 0 ||
      data?.streetAddress?.length === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please enter valid informations which fields have '*'");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setClientId(res.data.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleLogoChange = async (event) => {
    if (clientId?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage(
        "Please save your other basic informations first, then upload the logo."
      );
      return null;
    }

    const file = event.target.files[0];

    const formData = new FormData();

    formData.append("logo", file);
    formData.append("id", clientId);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/add`,
        method: "post",
        data: formData,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setClientData(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Logo updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Logo update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  console.log(clientData);

  return (
    <div className="w-full flex flex-col justify-center items-center py-[2rem]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold mb-5">Add Client</h1>
      <div className="w-[80vw] md:w-[60vw] bg-white">
        <form
          onSubmit={handleSubmit(addClint)}
          className="w-full border px-5 py-5 shadow-md space-y-5"
        >
          <div className="w-full">
            <p className="text-[20px] border-l-2 pl-3 text-slate-800 font-semibold text-left w-full">
              Basic Information
            </p>
            <div className="grid grid-cols-1 gap-3 2xl:grid-cols-4 md:grid-cols-3 mt-10">
              <InputField
                label={
                  <p className="font-medium">
                    Business Name <span className="text-red-600">*</span>
                  </p>
                }
                placeholder="Business Name"
                {...register("name")}
                defaultValue={clientData?.name}
              />
              <InputField
                label={
                  <p className="font-medium">
                    Industry Type <span className="text-red-600">*</span>
                  </p>
                }
                placeholder="Industry Type"
                {...register("industryType")}
                defaultValue={clientData?.industryType}
              />
              <InputField label="Email" {...register("email")} />
              <InputField
                label={
                  <p className="font-medium">
                    Phone Number <span className="text-red-600">*</span>
                  </p>
                }
                placeholder="Phone Number"
                {...register("phoneNumber")}
              />
              <InputField label="Website" {...register("website")} />
            </div>
          </div>
          <div className="w-full">
            <p className="text-[20px] mb-[2rem] border-l-2 pl-3 text-slate-800 font-semibold text-left w-full">
              Tax Information
            </p>
            <div className="grid grid-cols-1 gap-3 2xl:grid-cols-3 md:grid-cols-2 px-1">
              <InputField label="Business GSTIN" {...register("gstNo")} />
              <InputField label="Industry PAN" {...register("panNo")} />
              <section className="flex flex-col gap-2.5">
                <Label>Tax Treatment</Label>
                <select
                  className="max-w-[30rem] h-9 px-3 py-1 bg-white border"
                  defaultValue={"B2B"}
                  {...register("taxTreatment")}
                >
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </section>
            </div>
          </div>
          <div className="w-full">
            <p className="text-[20px] mb-[2rem] border-l-2 pl-3 text-slate-800 font-semibold text-left w-full">
              Address
            </p>
            <div className="grid grid-cols-1 gap-3 2xl:grid-cols-3 md:grid-cols-2 px-1">
              <InputField label={<p className="font-medium">Country <span className="text-red-600">*</span></p>} placeholder="Country" {...register("country")} />
              <InputField label={<p className="font-medium">State <span className="text-red-600">*</span></p>} placeholder="State" {...register("state")} />
              <InputField
                label={<p className="font-medium">Postal Code/ZIP Code <span className="text-red-600">*</span></p>}
                placeholder="Postal Code/ZIP Code"
                {...register("pincode")}
              />
              <InputField
                label={<p className="font-medium">Street Address <span className="text-red-600">*</span></p>}
                placeholder="Street Address"
                {...register("streetAddress")}
              />
            </div>
          </div>
          <Button className={"mt-3"}>Save</Button>
          <div>
            <input
              type="file"
              className="hidden"
              ref={logoref}
              onChange={handleLogoChange}
            />
            {clientData?.logo === undefined ? (
              <div
                onClick={() => logoref.current.click()}
                className="w-full h-[10rem] bg-blue-50/60 rounded-3xl flex justify-center items-center flex-col gap-3 border-2 border-dotted border-gray-300 cursor-pointer"
              >
                <FiPlus size={"30px"} />
                <p className="text-[18px]">Upload Logo</p>
                <p className="text-slate-500">Upload logo within 10 mb</p>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center w-full">
                <div className="w-[10rem]">
                  <img
                    src={`${SERVER_URL}/api/v1/file/image/${clientData?.logo}`}
                    className="w-[7rem]"
                    alt=""
                  />
                  <div className="w-full flex justify-end items-end mt-[-1.5rem]">
                    <button
                      type="button"
                      onClick={() => logoInputRef.current.click()}
                      className=""
                    >
                      <MdEditSquare className="text-[23px] text-slate-700 hover:bg-slate-100" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddClient;
