import React, { useState } from "react";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";

const InputField = React.forwardRef(function InputField(
  { label, placeholder, className, type, ...props },
  ref
) {
  const [show, setShow] = useState(false);

  if (type === "password") {
    return (
      <div className={`${className} grid w-full max-w-sm items-center gap-2.5`}>
        <section className="flex items-center text-[0.875rem] justify-between cursor-pointer">
          <Label htmlFor={type}>{label}</Label>
          {show === true ? (
            <p onClick={() => setShow(false)}>
              {<FaEyeSlash className="text-slate-400 text-[18px]" />}
            </p>
          ) : (
            <p onClick={() => setShow(true)}>
              <FaEye className="text-slate-400 text-[18px]" />
            </p>
          )}
        </section>
        <Input
          type={show === true ? "text" : "password"}
          id={show === true ? "text" : "password"}
          placeholder={placeholder || label}
          ref={ref}
          className="max-w-[30rem] bg-white"
          {...props}
        />
      </div>
    );
  }

  return (
    <div className={`grid w-full max-w-sm items-center gap-2.5`}>
      {
        label && <Label htmlFor={type}>{label}</Label>
      }
      <Input
        type={type}
        id={type}
        placeholder={placeholder || label}
        ref={ref}
        className={`${className} max-w-[30rem] bg-white`}
        {...props}
      />
    </div>
  );
});

export default InputField;