import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function AlertBox({ title, desc, setOpen, open, success }) {
  if (open) {
    setTimeout(() => setOpen(false), 3000);
  }

  if (open && success === true) {
    return (
      <div className="w-[90vw] sm:w-[25rem] fixed left-1/2 top-5 transform -translate-x-1/2 items-center z-[10000000000] shadow-sm">
        <div className="bg-green-500 text-slate-800 py-4 px-4 rounded-[7px]">
          <span className="w-full flex items-center justify-between gap-2">
            <div className="w-[80%]">
              <h1 className="text-white font-medium">{title}</h1>
            </div>
            <div className="20% text-center">
              <RxCross2
                className="text-[25px] cursor-pointer text-white"
                onClick={() => setOpen(false)}
              />
            </div>
          </span>
          {desc && <p className="w-[80%] text-[15px] text-white">{desc}</p>}
        </div>
      </div>
    );
  }

  if (open && success === false) {
    return (
      <div className="w-[90vw] sm:w-[25rem] fixed left-1/2 top-5 transform -translate-x-1/2 items-center z-[10000000000] shadow-2xl">
        <div className="bg-red-600 text-white py-4 px-4 rounded-[7px]">
          <span className="w-full flex items-center justify-between">
            <div className="w-[80%]">
              <h1 className="text-white font-medium text-[15px]">{title}</h1>
            </div>
            <div className="20% text-center">
              <RxCross2
                className="text-[25px] cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
          </span>
          {desc && <p className="w-[80%] text-[15px] text-white">{desc}</p>}
        </div>
      </div>
    );
  }
}

export default AlertBox;