import AccordionCompo from "@/components/AccordionCompo";
import AlertBox from "@/components/AlertBox";
import InputField from "@/components/InputField";
import Loading from "@/components/Loading";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { MdEditSquare } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function EditClient() {
  const { register, handleSubmit, setValue } = useForm();
  const logoref = useRef();
  const { id: clientId } = useParams("id");
  const [clientData, setClientData] = useState({});
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.auth?.userData);
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      navigate("/")
    }
  }, [isAuthenticated])

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/get?id=${clientId}`,
        method: "get",
        withCredentials: true,
      });
      setClientData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  async function addClint(data) {
    data = { ...data, id: clientId };
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleLogoChange = async (event) => {
    if (clientId?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage(
        "Please save your other basic informations first, then upload the logo."
      );
      return null;
    }

    const file = event.target.files[0];

    const formData = new FormData();

    formData.append("logo", file);
    formData.append("id", clientId);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/add`,
        method: "post",
        data: formData,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Logo updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Logo update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("name", clientData?.name)
    setValue("industryType", clientData?.industryType)
    setValue("email", clientData?.email)
    setValue("website", clientData?.website)
    setValue("phoneNumber", clientData?.phoneNumber)
    setValue("gstNo", clientData?.gstNo)
    setValue("panNo", clientData?.panNo)
    setValue("country", clientData?.country)
    setValue("state", clientData?.state)
    setValue("pincode", clientData?.pincode)
    setValue("streetAddress", clientData?.streetAddress)
  },[clientData])

  console.log(clientData);
  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center py-[2rem]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[80vw] md:w-[60vw]">
        <form
          onSubmit={handleSubmit(addClint)}
          className="w-full border px-5 py-5 shadow-md space-y-5"
        >
          <div className="w-full flex-col flex justify-center items-center">
            <input
              type="file"
              className="hidden"
              ref={logoref}
              onChange={handleLogoChange}
            />
            {clientData?.logo === undefined ? (
              <div
                onClick={() => logoref.current.click()}
                className="w-full h-[10rem] bg-blue-50/60 rounded-3xl flex justify-center items-center flex-col gap-3 border-2 border-dotted border-gray-300 cursor-pointer"
              >
                <FiPlus size={"30px"} />
                <p className="text-[18px]">Upload Logo</p>
                <p className="text-slate-500">Upload logo within 10 mb</p>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center w-[10rem]">
                <img
                  src={`${SERVER_URL}/api/v1/file/image/${clientData?.logo}`}
                  className="w-[10rem]"
                  alt=""
                />
                <div className="w-full flex justify-end items-end mt-[-1.5rem] ml-[4rem]">
                  <button
                    type="button"
                    onClick={() => logoref.current.click()}
                    className=""
                  >
                    <MdEditSquare className="text-[23px] text-slate-700 hover:bg-slate-100" />
                  </button>
                </div>
              </div>
            )}
            <div className="w-full mt-5">
              <p className="text-[20px] border-l-2 pl-3 text-slate-800 font-semibold text-left w-full">
                Basic Information
              </p>
              <div className="grid grid-cols-1 gap-3 2xl:grid-cols-4 md:grid-cols-3 place-items-center mt-5">
                <InputField
                  label="Business Name"
                  {...register("name")}
                  defaultValue={clientData?.name}
                />
                <InputField
                  label="Industry Type"
                  {...register("industryType")}
                  defaultValue={clientData?.industryType}
                />
                <InputField
                  label="Email"
                  {...register("email")}
                  defaultValue={clientData?.email}
                />
                <InputField
                  label="Website"
                  {...register("website")}
                  defaultValue={clientData?.website}
                />
                <InputField
                  label="Phone Number"
                  {...register("phoneNumber")}
                  defaultValue={clientData?.phoneNumber}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-[20px] mb-5 border-l-2 pl-3 text-slate-800 font-semibold text-left w-full">
              Tax Information
            </p>
            <div className="grid grid-cols-1 gap-3 2xl:grid-cols-3 md:grid-cols-2 px-1">
              <InputField
                label="Business GSTIN"
                {...register("gstNo")}
                defaultValue={clientData?.gstNo}
              />
              <InputField
                label="Industry PAN"
                {...register("panNo")}
                defaultValue={clientData?.panNo}
              />
              <section className="flex flex-col gap-2.5">
                <Label className="flex items-center gap-3">
                  Tax Treatment{" "}
                  <p className="text-slate-500">
                    Previous: {clientData?.taxTreatment}
                  </p>
                </Label>
                <select
                  className="max-w-[30rem] h-9 px-3 py-1 bg-white border"
                  defaultValue={"B2B"}
                  {...register("taxTreatment")}
                >
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </section>
            </div>
          </div>
          <div className="w-full">
            <p className="text-[20px] border-l-2 pl-3 text-slate-800 font-semibold text-left w-full mb-5">
              Address
            </p>
            <div className="grid grid-cols-1 gap-3 2xl:grid-cols-3 md:grid-cols-2 px-1">
              <InputField
                label="Country"
                {...register("country")}
                defaultValue={clientData?.country}
              />
              <InputField
                label="State"
                {...register("state")}
                defaultValue={clientData?.state}
              />
              <InputField
                label="Postal Code/ZIP Code"
                {...register("pincode")}
                defaultValue={clientData?.pincode}
              />
              <InputField
                label="Street Address"
                {...register("streetAddress")}
                defaultValue={clientData?.streetAddress}
              />
            </div>
          </div>
          <Button className={"mt-3"}>Save</Button>
        </form>
      </div>
    </div>
  );
}

export default EditClient;
