import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCircleUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";

function Crm() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      navigate("/")
    }
  }, [isAuthenticated])

  async function fetchClientData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/get?search=${search}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setClientData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function delClient(id) {
    if (!id) {
      setOpen(true);
      setSuccess(false);
      setMessage("Product Id not found");
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Successfully deleted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Delete failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  console.log(search);

  useEffect(() => {
    fetchClientData();
  }, [reload]);

  return (
    <div className="flex flex-col justify-center items-center w-full py-[2rem]">
      <h1 className="text-[40px] font-semibold">CRM</h1>
      <div className="flex justify-end items-end w-[98vw] xl:w-[80vw] mt-10 gap-3 flex-wrap">
        <Button asChild>
          <Link
            className="flex items-center gap-2"
            to={"/add-client"}
            target="_blank"
          >
            <FiPlusCircle size={"18px"} />
            Add Client
          </Link>
        </Button>
        <section className="flex items-center gap-2">
          <InputField
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
        </section>
      </div>
      <div className="w-[100vw] xl:w-[80vw] border px-5 py-3 mt-5 bg-white">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Logo</TableHead>
              <TableHead className="text-center">Business Name</TableHead>
              <TableHead className="text-center">Industry Type</TableHead>
              <TableHead className="text-center">GST No</TableHead>
              <TableHead className="text-center">PAN No</TableHead>
              <TableHead className="text-center">Tax Treatment</TableHead>
              <TableHead className="text-center">Email</TableHead>
              <TableHead className="text-center">Phone Number</TableHead>
              <TableHead className="text-center">Edit</TableHead>
              <TableHead className="text-center">Delete</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {clientData?.map((e, i) => (
              <TableRow key={i}>
                <TableCell>
                  {e?.logo === undefined ? (
                    <FaCircleUser className="text-[2rem] text-slate-500" />
                  ) : (
                    <img
                      src={`${SERVER_URL}/api/v1/file/image/${e?.logo}`}
                      className="w-[3rem]"
                      alt=""
                    />
                  )}
                </TableCell>
                <TableCell className="text-center">
                  {e?.name === undefined ? "NIL" : e?.name}
                </TableCell>
                <TableCell className="text-center">
                  {e?.industryType === undefined ? "NIL" : e?.industryType}
                </TableCell>
                <TableCell className="text-center">
                  {e?.gstNo === undefined ? "NIL" : e?.gstNo}
                </TableCell>
                <TableCell className="text-center">
                  {e?.panNo === undefined ? "NIL" : e?.panNo}
                </TableCell>
                <TableCell className="text-center">
                  {e?.taxTreatment === undefined ? "NIL" : e?.taxTreatment}
                </TableCell>
                <TableCell className="text-center">
                  {e?.email === undefined ? "NIL" : e?.email}
                </TableCell>
                <TableCell className="text-center">
                  {e?.phoneNumber === undefined ? "NIL" : e?.phoneNumber}
                </TableCell>
                <TableCell className="text-center">
                  <Button asChild>
                    <Link to={`/edit-client/${e?._id}`} target="_blank">
                      Edit
                    </Link>
                  </Button>
                </TableCell>
                <TableCell className="text-center">
                  <Button
                    variant="destructive"
                    onClick={() => delClient(e?._id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default Crm;
