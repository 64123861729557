import CardComponent from "@/components/CardComponent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import profileImg from "@/assets/profile.png";
import invoiceImg from "@/assets/invoice.png";
import quotationImg from "@/assets/quotationImg.png";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import Loading from "@/components/Loading";

function Dashboard() {
  const userData = useSelector((state) => state.auth?.userData);
  const [totalTds, setTotalTds] = useState(0);
  const [totalInvoiceBill, setTotalInvoiceBill] = useState(0);
  const [totalQuotationBill, setTotalQuotationBill] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalGst, setTotalGst] = useState(0);
  const [totalDue, setTotalDue] = useState(0);
  const [totalRecived, setTotalRecived] = useState(0);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalQuotation, setTotalQuotation] = useState(0)
  const [totalAccountDebited, setTotalAccountDebited] = useState(0)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      navigate("/")
    }
  }, [isAuthenticated])

  console.log("Dasboard")
  useEffect(() => {
    if(userData?.isOnboarded !== undefined && userData?.isOnboarded === false) {
      navigate("/change/password")
    }
  },[userData])

  async function fetchTotalValues() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/get/invoice/totals`,
        method: "get",
        withCredentials: true,
      });
      const invoiceValues = res.data.data?.invoice[0];
      const quotationValues = res.data.data?.quotation[0];
      const accountValues = res.data.data?.account[0];
      console.log(accountValues);
      setTotalRevenue(invoiceValues?.totalAmount || 0);
      setTotalGst(Number(invoiceValues?.totalIgst) + Number(invoiceValues?.totalCgst) * 2 || 0);
      setTotalInvoice(invoiceValues?.totalDocuments || 0);
      setTotalInvoiceBill(invoiceValues?.totalPrice || 0);
      setTotalQuotationBill(quotationValues?.totalPrice || 0);
      setTotalTds(invoiceValues?.totalTDS || 0);
      setTotalDue(invoiceValues?.totalDue || 0);
      setTotalRecived(invoiceValues?.totalRecived || 0);
      setTotalQuotation(quotationValues?.totalDocuments || 0)
      setTotalAccountDebited(accountValues?.totalPrice || 0)
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchTotalValues();
  }, []);

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center w-full py-[5rem]">
      <div className="flex flex-col w-[80vw]">
        <div className="flex justify-start items-center w-full gap-3">
          <img
            src={`${SERVER_URL}/api/v1/file/image/${userData?.logo}`}
            className="w-[5rem] object-cover rounded-full"
            alt=""
          />
          <section>
            <p className="text-[30px] font-semibold text-slate-500">
              Welcome Back!
            </p>
            <p className="text-[17px] font-medium text-slate-700">
              {userData?.name}
            </p>
          </section>
        </div>
        <div className="w-full grid grid-cols-2 xl:grid-cols-5 md:grid-cols-2 gap-3 mt-10">
          <section className="bg-blue-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Invoices
            </p>
            <p className="text-[30px] font-semibold">₹ {totalInvoice}</p>
          </section>
          <section className="bg-cyan-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Quotation
            </p>
            <p className="text-[30px] font-semibold">₹ {totalQuotation}</p>
          </section>
          <section className="bg-sky-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Invoice Bill
            </p>
            <p className="text-[30px] font-semibold">₹ {totalInvoiceBill}</p>
          </section>
          <section className="bg-sky-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Quotation Bill
            </p>
            <p className="text-[30px] font-semibold">₹ {totalQuotationBill}</p>
          </section>
          <section className="bg-cyan-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">Total GST</p>
            <p className="text-[30px] font-semibold">₹ {totalGst}</p>
          </section>
          <section className="bg-green-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">Total TDS</p>
            <p className="text-[30px] font-semibold">₹ {totalTds}</p>
          </section>
          <section className="bg-purple-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Company Revenue
            </p>
            <p className="text-[30px] font-semibold">₹ {totalRevenue}</p>
          </section>
          <section className="bg-violet-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Due Amount
            </p>
            <p className="text-[30px] font-semibold">₹ {totalDue}</p>
          </section>
          <section className="bg-indigo-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Total Recived Amount
            </p>
            <p className="text-[30px] font-semibold">₹ {totalRecived}</p>
          </section>
          <section className="bg-indigo-50 max-w-[100%] p-3 rounded-[7px]">
            <p className="text-[16px] font-medium text-slate-500">
              Account Book DR
            </p>
            <p className="text-[30px] font-semibold">₹ {totalAccountDebited}</p>
          </section>
        </div>
        <div className="flex gap-3 mt-[3rem] flex-col md:flex-row">
          <CardComponent
            title={"Complete Your Profile"}
            desc={"Please fill all the details"}
            width="w-[80vw] md:w-[30rem]"
            footer={
              <Link
                to={"/my-profile"}
                className="text-center w-full text-[13px] text-slate-500 flex items-center gap-2 justify-center"
              >
                View Your Profile <FaArrowRightLong />
              </Link>
            }
          >
            <div className="w-full h-[13rem]">
              <img
                src={profileImg}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <Button
              className="w-full mt-5 bg-white border-blue-500 border text-blue-700"
              asChild
            >
              <Link to={"my-profile"}>Complete Your Profile</Link>
            </Button>
          </CardComponent>
          <CardComponent
            title={"Invoice"}
            desc={"Generate a new invoice"}
            width="w-[80vw] md:w-[30rem]"
            footer={
              <Link className="text-center w-full text-[13px] text-slate-500 flex items-center gap-2 justify-center" to={"/report/invoice"}>
                View All Invoice <FaArrowRightLong />
              </Link>
            }
          >
            <div className="w-full h-[13rem]">
              <img
                src={invoiceImg}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <Button
              className="w-full mt-5 bg-white border-blue-500 border text-blue-700"
              asChild
            >
              <Link to={"/invoice/generate"}>Generate An Invoice</Link>
            </Button>
          </CardComponent>
          <CardComponent
            title={"Quotation"}
            desc={"Generate a new quotation"}
            width="w-[80vw] md:w-[30rem]"
            footer={
              <Link className="text-center w-full text-[13px] text-slate-500 flex items-center gap-2 justify-center" to={"/report/quotation"}>
                View All Quotation <FaArrowRightLong />
              </Link>
            }
          >
            <div className="w-full h-[13rem]">
              <img
                src={quotationImg}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <Button
              className="w-full mt-5 bg-white border-blue-500 border text-blue-700"
              asChild
            >
              <Link to={"/quotation/generate"}>Make A Quotation</Link>
            </Button>
          </CardComponent>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
