import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { SERVER_URL } from "@/constant";
import { PopoverClose } from "@radix-ui/react-popover";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";
import AlertBox from "@/components/AlertBox";
import { FiFilePlus } from "react-icons/fi";
import AddItem from "@/components/AddItem";
import { LuPencilLine } from "react-icons/lu";
import { MdOutlineDiscount } from "react-icons/md";
import AddTAndC from "../components/AddTAndC";
import { RxCross1 } from "react-icons/rx";
import UpdateItem from "@/components/UpdateItem";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";

function Invoice() {
  const [invoiceDate, setInvoiceDate] = useState(Date.now());
  const [dueDate, setDueDate] = useState(undefined);
  const userData = useSelector((state) => state.auth.userData);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState("Select A Client");
  const [invoiceId, setInvoiceId] = useState("");
  const [gstType, setGstType] = useState("IGST");
  const [addedItems, setAddedItems] = useState([]);
  const [invoiceReload, setInvoiceReload] = useState(false);
  const [discountValue, setDiscountValue] = useState();
  const [discountType, setDiscountType] = useState("percentage");
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [selectedSign, setSelectedSign] = useState("");
  const [selectedterms, setSelectedTerms] = useState([]);
  const [isDownloadEnable, setIsDownloadEnable] = useState(false);
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [enableTAndC, setEnableTAndC] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const isAuthenticated = useSelector(state => state.auth?.isAuthenticated)

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      navigate("/")
    }
  }, [isAuthenticated])

  async function fetchUserData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/get-user`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setSelectedSign(res.data.data?.signature);
      setSelectedTerms(res.data.data?.termsAndCond);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUserData();
  }, [reload]);

  //initializing due date
  useEffect(() => {
    let today = Date.now();
    let futureDueDate = new Date(today + 3 * 24 * 60 * 60 * 1000);
    setDueDate(futureDueDate);
  }, [invoiceDate]);

  async function fetchClientData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/client/get`,
        method: "get",
        withCredentials: true,
      });
      //console.log(res.data);
      setClientData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function fetchInvoiceData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/get/${invoiceId}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data.data);
      setAddedItems(res.data.data?.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const [reloadInventory, setReloadInventory] = useState(false)

  async function fetchItemsData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/inventory/get`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setItemsData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteItem(item) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/item/delete`,
        method: "delete",
        data: {
          id: invoiceId,
          item: item,
        },
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setInvoiceReload((prev) => !prev);
    }
  }

  async function deleteTerm(item) {
    console.log(userData?._id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/user/term/delete`,
        method: "delete",
        data: {
          id: userData?._id,
          term: item,
        },
        withCredentials: true,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  console.log(invoiceId);

  useEffect(() => {
    fetchInvoiceData();
  }, [invoiceReload, invoiceId]);

  function selectAClient(data) {
    setSelectedClient(data);
    setDropdownTitle(data?.name);
    setDropdownOpen(false);
  }
  async function addInvoice() {
    let data = {
      client: selectedClient,
      clientId: selectedClient?._id,
      dateStamp: invoiceDate,
      dueDate: dueDate?.toString()?.split(" ").slice(0, 4).join(" "),
      gstType: gstType,
    };

    //console.log(data);

    if (invoiceId?.length > 0) {
      data = { ...data, id: invoiceId };
    }

    if (!selectAClient || !dueDate || gstType?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a due date, a client and the GST type");
      return null;
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setInvoiceId(res.data.data?._id);
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    fetchItemsData();
  }, [reloadInventory])

  const [total, setTotal] = useState(0);
  const [totalIgst, setTotalIgst] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCgst, setTotalCgst] = useState(0);
  const [totalSgst, setTotalSgst] = useState(0);
  const [originalTotalAmount, setOriginalTotalAmount] = useState(0);

  useEffect(() => {
    let rawAmount = 0;
    let rawIgst = 0;
    let rawCgst = 0;
    let rawSgst = 0;
    let rawTotal = 0;

    addedItems?.map((e) => {
      rawAmount = rawAmount + e?.amount;
      rawIgst = rawIgst + e?.igst;
      rawCgst = rawCgst + e?.cgst;
      rawSgst = rawSgst + e?.sgst;
      rawTotal = rawTotal + e?.total;
    });

    console.log("amount ", rawAmount);
    console.log("IGST ", rawIgst);
    console.log("total ", rawTotal);

    setTotal(Math.ceil(Number(rawAmount)));
    setTotalIgst(Math.ceil(Number(rawIgst)));
    setTotalCgst(Math.ceil(Number(rawCgst)));
    setTotalSgst(Math.ceil(Number(rawSgst)));
    setOriginalTotalAmount(Math.ceil(Number(rawTotal)));
    setTotalAmount(Math.ceil(Number(rawTotal)));
  }, [addedItems]);

  //discount calculation
  useEffect(() => {
    if (discountValue > 0) {
      let newTotalAmount = originalTotalAmount;

      if (discountType === "percentage") {
        newTotalAmount = Math.ceil(
          originalTotalAmount - originalTotalAmount * (discountValue / 100)
        );
      } else {
        newTotalAmount = Math.ceil(originalTotalAmount - discountValue);
      }

      setTotalAmount(newTotalAmount);
    } else {
      setTotalAmount(originalTotalAmount);
    }
  }, [discountType, discountValue, originalTotalAmount]);

  //console.log(enableTAndC);

  async function addMore() {
    if (selectedSign?.length === 0 || selectedSign?.length === undefined) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a signature");
      return null;
    }
    console.log(selectedSign?.length);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/add`,
        method: "post",
        data: {
          id: invoiceId,
          discountValue,
          discountType,
          amount: total,
          totalIgst: totalIgst,
          totalCgst: totalCgst,
          totalSgst: totalSgst,
          totalPrice: totalAmount,
          termsAndCond: enableTAndC,
          paymentDue: totalAmount,
          finalBillAmount: total,
        },
        withCredentials: true,
      });
      setInvoiceId(res.data.data?._id);
      console.log(res.data.data)
      // setIsDownloadEnable(true)
      window.open(
        `${SERVER_URL}/api/v1/invoice/generate/invoice/${invoiceId}`,
        "_blank"
      );
      setOpen(true);
      setSuccess(true);
      setMessage("Data updated");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data update failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //console.log(dueDate);

  return (
    <div className="w-full flex flex-col justify-center items-center py-[2rem]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <div className="w-[100vw] 2xl:w-[65vw] lg:w-[80vw] flex flex-col justify-center items-center shadow p-[1rem] bg-white border border-slate-400 rounded-[7px]">
        <h1 className="text-[30px] font-semibold">Invoice</h1>
        <div className="w-full flex justify-between items-center">
          <div className="space-y-3">
            <section className="grid grid-cols-2">
              <p>
                Invoice Date <span className="text-red-600">*</span>
              </p>
              <Popover>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-[12rem] border-white border-b-slate-300 rounded-none"
                  >
                    {new Date(invoiceDate)
                      .toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      .replace(/,/g, "")}{" "}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <Calendar
                    mode="single"
                    selected={invoiceDate}
                    onSelect={setInvoiceDate}
                    disabled={(date) => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      date.setHours(0, 0, 0, 0);
                      return date < today;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </section>
            <section className="grid grid-cols-2">
              <p>
                Due Date <span className="text-red-600">*</span>
              </p>
              <Popover>
                <PopoverTrigger>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-[12rem] border-white border-b-slate-300 rounded-none"
                  >
                    {dueDate === undefined
                      ? "Pick a date"
                      : new Date(dueDate)
                        .toLocaleDateString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        .replace(/,/g, "")}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <Calendar
                    mode="single"
                    selected={dueDate}
                    onSelect={setDueDate}
                    disabled={(date) => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      date.setHours(0, 0, 0, 0);
                      return date < today;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </section>
          </div>
          <img
            src={`${SERVER_URL}/api/v1/file/image/${userData?.logo}`}
            className="w-[10rem]"
            alt=""
          />
        </div>
        <div className="flex w-full justify-between gap-5 mt-5">
          <div className="w-1/2 border-slate-300 border px-5 py-3 rounded-xl space-y-1">
            <h2 className="mb-3 text-[20px] font-semibold">Billed By :</h2>
            <div className="">
              <div className="flex">
                <p className="font-medium w-[10rem]">Business Name: </p>
                <span className="font-medium text-slate-500">
                  {userData?.name}
                </span>
              </div>
              <div className="flex">
                <p className="font-medium w-[10rem]">Phone Number: </p>
                <span className="font-medium text-slate-500">
                  {userData?.phoneNumber}
                </span>
              </div>
              <div className="flex">
                <p className="font-medium w-[10rem]">GST No: </p>
                <span className="font-medium text-slate-500">
                  {userData?.gstNo}
                </span>
              </div>
              <div className="flex">
                <p className="font-medium w-[10rem]">PAN No: </p>
                <span className="font-medium text-slate-500">
                  {userData?.panNo}
                </span>
              </div>
              <div className="flex">
                <p className="font-medium w-[10rem]">Address: </p>
                <span className="font-medium text-slate-500">
                  {userData?.address + ", " + userData?.pincode}
                </span>
              </div>
            </div>
          </div>
          <div className="w-1/2 border-slate-300 border px-5 py-3 rounded-xl">
            <h2 className="mb-3 text-[20px] font-semibold">Billed To :</h2>
            <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
              <DropdownMenuTrigger
                onClick={() => setDropdownOpen((prev) => !prev)}
                className="border w-full py-1 rounded-xl bg-white flex justify-between items-center px-3"
              >
                {dropdownTitle} <IoIosArrowDown />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {clientData?.map((e, i) => (
                  <DropdownMenuItem
                    className="font-medium"
                    onClick={() => selectAClient(e)}
                    key={i}
                  >
                    {e?.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {selectedClient === null ? (
              <div className="w-full flex justify-center items-center mt-2 gap-1 flex-col">
                <p>Select a client</p>
                <p>or</p>
                <Button asChild>
                  <Link
                    className="flex items-center gap-2"
                    to={"/add-client"}
                    target="_blank"
                  >
                    <FiPlusCircle size={"18px"} />
                    Add Client
                  </Link>
                </Button>
              </div>
            ) : (
              <div className="mt-2">
                <div className="flex gap-5">
                  <p className="font-medium">Business Name: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.name}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">Phone Number: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.phoneNumber}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">Email: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.email?.length > 0 ? selectedClient?.email : "N/A"}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">Website: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.website?.length > 0 ? selectedClient?.website : "N/A"}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">GST No: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.gstNo?.length > 0 ? selectedClient?.gstNo : "N/A"}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">PAN No: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.panNo?.length > 0 ? selectedClient?.panNo : "N/A"}
                  </span>
                </div>
                <div className="flex gap-5">
                  <p className="font-medium">Address: </p>
                  <span className="font-medium text-slate-500">
                    {selectedClient?.streetAddress +
                      ", " +
                      selectedClient?.pincode}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full mt-5 border border-slate-300 p-5 rounded-xl flex justify-between items-center">
          <div className="w-full">
            <p className="font-medium mb-2">
              Configure GST <span className="text-red-600">*</span>
            </p>
            <div className="w-full flex gap-2">
              <label className="flex gap-2">
                <input
                  type="radio"
                  name="gst"
                  onClick={() => setGstType("IGST")}
                  checked={gstType === "IGST"} // Control the checked state
                />
                IGST
              </label>
              <label className="flex gap-2">
                <input
                  type="radio"
                  name="gst"
                  onClick={() => setGstType("CGST & SGST")}
                  checked={gstType === "CGST & SGST"} // Control the checked state
                />
                CGST & SGST
              </label>
            </div>
          </div>
          <div className="w-full flex justify-end items-start mt-5">
            {invoiceId?.length > 0 ? (
              <Button onClick={addInvoice} disabled>
                {invoiceId?.length > 0 ? "Update" : "Save"} & Continue
              </Button>
            ) : (
              <Button onClick={addInvoice}>
                {invoiceId?.length > 0 ? "Update" : "Save"} & Continue
              </Button>
            )}
          </div>
        </div>
        <div className="w-full mt-5">
          <div className="grid grid-cols-[repeat(22,_1fr)] bg-sky-100 py-2 rounded-[6px] text-sky-700 px-5 text-[14px] gap-4">
            <span className="col-span-3 font-medium">Items</span>
            <span className="col-span-2 font-medium hidden md:grid">
              HSN/SAC
            </span>
            <span className="col-span-2 font-medium hidden md:grid">
              GST Rate
            </span>
            <span className="col-span-1 font-medium hidden md:grid">
              Quantity
            </span>
            <span className="col-span-2 font-medium hidden md:grid">Unit</span>
            <span className="col-span-2 font-medium hidden md:grid">Rate</span>
            <span className="col-span-2 font-medium hidden md:grid">
              Amount
            </span>
            {gstType === "IGST" ? (
              <span className="col-span-2 font-medium hidden md:grid">
                IGST
              </span>
            ) : (
              <span className="col-span-2 font-medium hidden md:grid">
                CGST
              </span>
            )}
            {gstType === "IGST" ? (
              ""
            ) : (
              <span className="col-span-2 font-medium hidden md:grid">
                SGST
              </span>
            )}
            <span className="col-span-2 font-medium hidden md:grid">Total</span>
            <span className="col-span-1 font-medium hidden md:grid">Edit</span>
            <span className="col-span-1 font-medium hidden md:grid">
              Delete
            </span>
          </div>
          {addedItems?.length === 0 ? (
            <div className="w-full flex justify-center items-center flex-col mt-5 gap-3">
              <p className="w-full text-center font-medium text-slate-500">
                No items in the invoice
              </p>
              {invoiceId?.length === 0 ? (
                <p className="text-[15px] text-slate-500">
                  <span className="text-red-500">*</span>{" "}
                  Please fill the previous fields and click on{" "}
                  <span className="font-medium text-[15px] text-slate-700">"Save & Continue"</span>
                </p>
              ) : (
                ""
              )}
            </div>
          ) : (
            addedItems?.map((e, i) => (
              <div className="w-full mt-5 bg-sky-100/30 py-3 rounded-[17px]">
                <div className="hidden md:grid grid-cols-[repeat(11,_1fr)] md:grid-cols-[repeat(22,_1fr)] text-slate-700 px-5 text-[14px] gap-4">
                  <span className="col-span-3 font-normal truncate">
                    {e?.itemName}
                  </span>
                  <span className="col-span-2 font-normal truncate">
                    {e?.hsnNo}
                  </span>
                  <span className="col-span-2 font-normal truncate">
                    {e?.gstRate}%
                  </span>
                  <span className="col-span-1 font-normal truncate">
                    {e?.quantity}
                  </span>
                  <span className="col-span-2 font-normal truncate">
                    {e?.unit?.charAt(0)?.toUpperCase() + e?.unit?.slice(1)}
                  </span>
                  <span className="col-span-2 font-normal truncate">
                    {e?.rate}
                  </span>
                  <span className="col-span-2 font-normal truncate">
                    {e?.amount}
                  </span>
                  {gstType === "IGST" ? (
                    <span className="col-span-2 font-medium hidden md:grid">
                      ₹{e?.igst}
                    </span>
                  ) : (
                    <span className="col-span-2 font-medium hidden md:grid">
                      ₹{e?.cgst}
                    </span>
                  )}
                  {gstType === "IGST" ? (
                    ""
                  ) : (
                    <span className="col-span-2 font-medium hidden md:grid">
                      ₹{e?.sgst}
                    </span>
                  )}
                  <span className="col-span-2 font-normal truncate">
                    {e?.total}
                  </span>
                  <span className="col-span-1 font-normal truncate">
                    <UpdateItem
                      setReload={setInvoiceReload}
                      data={e}
                      invoiceId={invoiceId}
                      gstType={gstType}
                    />
                  </span>
                  <span className="col-span-1 font-normal truncate">
                    <Button
                      className="p-0 h-auto bg-transparent text-black border-transparent hover:bg-slate-100"
                      variant="ghost"
                      onClick={() => deleteItem(e)}
                    >
                      <RxCross1 size={"23px"} className="text-red-600" />
                    </Button>
                  </span>
                </div>
              </div>
            ))
          )}
          <div className="w-full flex justify-end items-end mt-5">
            {invoiceId?.length > 0 ? (
              <AddItem
                gstType={gstType}
                setReload={setInvoiceReload}
                invoiceId={invoiceId}
                itemsData={itemsData}
                setReloadInventory={setReloadInventory}
              />
            ) : (
              <Button
                disabled
                className="text-center py-5 border-dashed border-slate-700"
                variant="outline"
              >
                <p className="flex items-center gap-[7px] font-medium w-full justify-center">
                  <FiFilePlus size={"18px"} className="text-sky-600" />
                  Add Item
                </p>
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-end justify-end w-full mt-10">
          <div className="w-[25rem]">
            <div className="">
              <div className="w-full space-y-2 border-t-[1px] border-slate-500 border-dashed pt-5">
                <div className="flex justify-between w-full">
                  <p className="text-[16px] font-medium">Amount</p>
                  <p className="text-[16px] font-normal">₹ {total}</p>
                </div>
                {gstType === "IGST" ? (
                  <>
                    <div className="flex justify-between w-full">
                      <p className="text-[16px] font-medium">IGST</p>
                      <p className="text-[16px] font-normal">₹ {totalIgst}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full flex justify-between">
                      <p className="text-[16px] font-medium">CGST</p>
                      <p className="text-[16px] font-normal">₹ {totalCgst}</p>
                    </div>
                    <div className="w-full flex justify-between">
                      <p className="text-[16px] font-medium">SGST</p>
                      <p className="text-[16px] font-normal">₹ {totalSgst}</p>
                    </div>
                  </>
                )}
                {enableDiscount === true ? (
                  <>
                    <p className="text-[16px] font-medium">Discount</p>
                    <div className="flex w-[10rem] gap-1">
                      <input
                        type=""
                        className="border-b-[1px] focus:outline-none border-dashed border-slate-500 w-full selection:border-none"
                        onChange={(e) => {
                          setDiscountValue(e?.target?.value);
                          setIsDownloadEnable(false);
                        }}
                      />
                      <select
                        className="w-[4rem] px-2 bg-white cursor-pointer"
                        onChange={(e) => setDiscountType(e?.target?.value)}
                      >
                        <option value="percentage">%</option>
                        <option value="amount">₹</option>
                      </select>
                    </div>
                  </>
                ) : (
                  <div
                    onClick={() => setEnableDiscount(true)}
                    className="flex items-center gap-1 text-[15px] text-sky-800/70 font-medium hover:text-sky-800 cursor-pointer hover:underline"
                  >
                    <MdOutlineDiscount />
                    Add Discount
                  </div>
                )}
              </div>
              <div className="w-full h-[1px] bg-slate-300 mt-5 mb-5"></div>
              <div className="flex justify-between items-center">
                <p className="text-[25px] font-semibold">Total</p>
                <p className="text-[25px] font-semibold">₹ {totalAmount}</p>
              </div>
              <div className="w-full h-[1px] bg-slate-300 mt-5 mb-5"></div>
            </div>
            <div className="w-full">
              <p className="mb-5 font-semibold">Signature</p>
              <img
                src={`${SERVER_URL}/api/v1/file/image/${userData?.signature}`}
                className="w-[10rem] cursor-pointer"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="w-full mt-5 mb-5">
          <div className="flex items-center space-x-2 p-3">
            <Switch
              id="termsAndCond"
              checked={enableTAndC}
              onCheckedChange={() => setEnableTAndC((prev) => !prev)}
            />
            <Label htmlFor="termsAndCond">Terms & Conditions</Label>
          </div>
        </div>
        {enableTAndC === true ? (
          <div className="w-full bg-white border border-slate-500 border-dashed mt-5 px-5 py-5 rounded-[10px]">
            <div className="flex justify-between items-center w-full">
              <h2 className="font-semibold text-[17px]">Terms And Condition</h2>
              {selectedterms?.length >= 5 ? (
                ""
              ) : (
                <AddTAndC invoiceId={invoiceId} setReload={setReload} />
              )}
            </div>
            <div className="w-full flex flex-col gap-2 justify-center items-center mt-3">
              {selectedterms?.length > 0
                ? selectedterms?.map((e, i) => (
                  <div className="w-full flex items-center justify-between">
                    <section className="flex gap-5 pb-2 items-end justify-start border-b-[1px] w-full border-slate-400">
                      <p>{i + 1}</p>
                      <p className="text-slate-800 font-medium" key={i}>
                        {e}
                      </p>
                    </section>
                    <Button variant="ghost" onClick={() => deleteTerm(e)}>
                      <RxCross1 />
                    </Button>
                  </div>
                ))
                : null}
            </div>
          </div>
        ) : null}
        <div className="w-full flex justify-end items-start mt-5 gap-5">
          <Button onClick={addMore}>Generate Bill</Button>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
