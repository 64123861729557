import AlertBox from "@/components/AlertBox";
import DialogBox from "@/components/DialogBox";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { DialogClose } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { SERVER_URL } from "@/constant";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import { FiFilter, FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCloudDownload } from "react-icons/io";
import Loading from "@/components/Loading";
import { SlCalender } from "react-icons/sl";
import { TfiReload } from "react-icons/tfi";
import { useSelector } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

function AccountBook() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [amount, setAmount] = useState(0);
  const [accountType, setAccountType] = useState("Expense");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const fileref = useRef(null);
  const [enableAddRecord, setEnableAddRecord] = useState(false);
  const [enableEditRecord, setEnableEditRecord] = useState(false);
  const [dateStamp, setDateStamp] = useState(new Date());
  const [tdsPercentage, setTdsPercentage] = useState(0);
  const [tdsAMount, setTdsAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);
  const [editData, setEditData] = useState({});
  const [resetData, setResetData] = useState(false);
  const [editRecordId, setEditRecordId] = useState("");

  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      navigate("/")
    }
  }, [isAuthenticated])

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/get?search=${search}&startDate=${startDate}&endDate=${endDate}`,
        method: "get",
        withCredentials: true,
      });
      console.log(res.data);
      setRecordData(res.data.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Data fetch failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [reload, startDate, endDate]);

  function handleFileChange(event) {
    const arrayFiles = Array.from(event.target.files);
    if (arrayFiles.length <= 5) {
      setFiles(arrayFiles);
    } else {
      setOpen(true);
      setSuccess(false);
      setMessage("You can upload 5 photos maximum");
    }
  }

  async function deleteRecord(id) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/delete/${id}`,
        method: "delete",
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function generateReport() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/generate`,
        method: "post",
        data: {
          startDate,
          endDate,
          data: recordData,
          topic: search,
        },
        withCredentials: true,
      });
      console.log(res.data.data);
      setOpen(true);
      setSuccess(true);
      setMessage("Redirecting in some time...");
      window.open(res.data?.data?.link, "_blank");
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setMessage("Invoice generate failed");
    } finally {
      setLoading(false);
    }
  }

  async function addRecord() {
    console.log(amount);
    console.log(accountType);
    console.log(description);

    if (
      amount === 0 ||
      accountType?.length === 0 ||
      description?.length === 0
    ) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please enter a valid amount, account type and description");
      return null;
    }

    if (files?.length >= 5) {
      setOpen(true);
      setSuccess(false);
      setMessage("Only 5 documents can be uploaded");
      return null;
    }

    const formData = new FormData();

    files?.map((e) => {
      formData.append("accountDocument", e);
    });
    formData.append("amount", amount);
    formData.append("accountType", accountType);
    formData.append("description", description);
    formData.append("dateStamp", dateStamp);
    formData.append("tdsPercentage", tdsPercentage);
    formData.append("tdsAmount", tdsAMount);
    formData.append("paidAmount", paidAmount);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/add`,
        method: "post",
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/formData",
        },
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Record added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record add failed");
      console.log(error);
    } finally {
      setLoading(false);
      setEnableAddRecord(false);
    }
  }

  async function editRecord() {
    console.log(dateStamp);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/add`,
        method: "post",
        data: {
          id: editRecordId,
          amount: amount,
          tdsPercentage: tdsPercentage,
          tdsAmount: tdsAMount,
          accountType: accountType,
          recordDate: dateStamp,
          paidAmount: paidAmount,
          description: description,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Record added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record add failed");
      console.log(error);
    } finally {
      setLoading(false);
      setEnableEditRecord(false);
    }
  }

  async function deleteDocument(id, filename) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/file/delete`,
        method: "delete",
        data: {
          id,
          filename,
        },
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setReload((prev) => !prev);
    }
  }

  async function addFile(max, id) {
    if (files?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please select a file");
      return null;
    }

    if (files?.length > 5 - max) {
      setOpen(true);
      setSuccess(false);
      setMessage(`Only ${5 - max} documents can be uploaded`);
      return null;
    }

    const formData = new FormData();

    files?.map((e) => {
      formData.append("accountDocument", e);
    });
    formData.append("id", id);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/account/file/add`,
        method: "post",
        data: formData,
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/formData",
        },
      });
      setReload((prev) => !prev);
      setFiles([]);
      setOpen(true);
      setSuccess(true);
      setMessage("Record added successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Record add failed");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setTdsAmount(Math.ceil(Number(amount * (tdsPercentage / 100))));
    setPaidAmount(Math.ceil(Number(amount - amount * (tdsPercentage / 100))));
  }, [tdsPercentage, amount]);

  useEffect(() => {
    if (editData?.amount !== undefined) {
      setDateStamp(editData?.recordDate);
      setAmount(editData?.amount);
      setTdsPercentage(editData?.tdsPercentage);
      setAccountType(editData?.accountType);
      setDescription(editData?.description);
    } else {
      setDateStamp(new Date());
      setAmount(0);
      setTdsPercentage(0);
      setAccountType("");
      setDescription("");
    }
  }, [editData, resetData]);

  if (loading) {
    return (
      <div className="flex h-[100vh] justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  if (enableAddRecord) {
    return (
      <div className="w-full flex justify-center items-center flex-col py-10">
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <h1 className="text-[40px] font-semibold">Add Account</h1>
        <div className="w-[60vw] mt-10">
          <div className="w-full grid grid-cols-2 gap-3">
            <InputField
              label="Amount"
              onChange={(e) => setAmount(e?.target?.value)}
            />
            <InputField
              label="TDS Deduction(in %)"
              onChange={(e) => setTdsPercentage(e?.target?.value)}
            />
            <InputField
              label="TDS Deduction(in rupees)"
              value={tdsAMount}
              readOnly
              onChange={(e) => setTdsAmount(e?.target?.value)}
            />
            <InputField
              label="Paid Amount"
              value={paidAmount === 0 ? amount : paidAmount}
              readOnly
              onChange={(e) => setPaidAmount(e?.target?.value)}
            />
            <section className="flex flex-col gap-[0.725rem] max-w-[24rem]">
              <Label>Select Account Type</Label>
              <select
                className="max-w-[27rem] h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(e) => setAccountType(e?.target?.value)}
              >
                <option value="Expense">Expense</option>
                <option value="Income">Income</option>
                <option value="Salary">Salary</option>
                <option value="Vendor">Vendor</option>
                <option value="Asset">Asset</option>
                <option value="Tax">Tax</option>
                <option value="Service">Service</option>
                <option value="Miscellaneous">Miscellaneous</option>
                <option value="Other">Other</option>
              </select>
            </section>
            <InputField
              label="To"
              onChange={(e) => setDescription(e?.target?.value)}
            />
            <Popover>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Record Date</Label>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-full border rounded-none"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {new Date(dateStamp)
                      .toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      .replace(/,/g, "")}{" "}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
              </div>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={dateStamp}
                  onSelect={setDateStamp}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div
            onClick={() => fileref.current.click()}
            className="w-full h-[10rem] mt-5 bg-blue-50/60 rounded-3xl flex justify-center items-center flex-col gap-3 border-2 border-dotted border-gray-300 cursor-pointer"
          >
            <input
              type="file"
              className="hidden"
              ref={fileref}
              onChange={(e) => handleFileChange(e)}
              multiple
            />
            <FiPlus size={"20px"} />
            <p className="text-[17px]">Upload Documents</p>
            <p className="text-slate-500">
              Maximum 5 documents can be uploaded
            </p>
            <span className="text-slate-500 text-[15px]">
              {files?.length} document selected
            </span>
          </div>
          <div className="flex justify-end items-center gap-3">
            <Button onClick={() => setEnableAddRecord(false)} className="mt-5">
              Back
            </Button>
            <Button onClick={addRecord} className="mt-5">
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (enableEditRecord) {
    return (
      <div className="w-full flex justify-center items-center flex-col py-10">
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <h1 className="text-[40px] font-semibold">Add Account</h1>
        <div className="w-[60vw] mt-10">
          <div className="w-full grid grid-cols-2 gap-3">
            <InputField
              label="Amount"
              value={amount}
              onChange={(e) => setAmount(e?.target?.value)}
            />
            <InputField
              label="TDS Deduction(in %)"
              value={tdsPercentage}
              onChange={(e) => setTdsPercentage(e?.target?.value)}
            />
            <InputField
              label="TDS Deduction(in rupees)"
              value={tdsAMount}
              readOnly
              onChange={(e) => setTdsAmount(e?.target?.value)}
            />
            <InputField
              label="Paid Amount"
              value={paidAmount === 0 ? amount : paidAmount}
              readOnly
              onChange={(e) => setPaidAmount(e?.target?.value)}
            />
            <section className="flex flex-col gap-[0.725rem] max-w-[24rem]">
              <Label>Select Account Type</Label>
              <select
                className="max-w-[27rem] h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                onChange={(e) => setAccountType(e?.target?.value)}
                value={accountType}
              >
                <option value="Expense">Expense</option>
                <option value="Income">Income</option>
                <option value="Salary">Salary</option>
                <option value="Vendor">Vendor</option>
                <option value="Asset">Asset</option>
                <option value="Tax">Tax</option>
                <option value="Service">Service</option>
                <option value="Miscellaneous">Miscellaneous</option>
                <option value="Other">Other</option>
              </select>
            </section>
            <InputField
              label="To"
              value={description}
              onChange={(e) => setDescription(e?.target?.value)}
            />
            <Popover>
              <div className="grid gap-2.5 max-w-[24rem]">
                <Label>Record Date</Label>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="flex items-center gap-3 w-full border rounded-none"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {new Date(dateStamp)
                      .toLocaleDateString("en-US", {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                      .replace(/,/g, "")}{" "}
                    <SlCalender />
                  </Button>
                </PopoverTrigger>
              </div>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={dateStamp}
                  onSelect={setDateStamp}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex justify-end items-center gap-3">
            <Button onClick={() => setEnableEditRecord(false)} className="mt-5">
              Back
            </Button>
            <Button onClick={editRecord} className="mt-5">
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 px-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <h1 className="text-[40px] font-semibold">Account Book</h1>
      <div className="w-full mt-5">
        <div className="w-full flex justify-between items-center gap-3">
          <div className="flex gap-2 items-center">
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {startDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "Start Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={startDate}
                  onSelect={setStartDate}
                />
              </PopoverContent>
            </Popover>
            <p>To</p>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline">
                  {endDate?.toString()?.split(" ")?.slice(1, 4)?.join(" ") ||
                    "End Date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  mode="single"
                  selected={endDate}
                  onSelect={setEndDate}
                />
              </PopoverContent>
            </Popover>
            <Button
              onClick={() => {
                setStartDate(undefined);
                setEndDate(undefined);
              }}
            >
              Clear
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              onClick={() => {
                setEnableAddRecord(true);
                setResetData((prev) => !prev);
                setEditData({});
              }}
            >
              <p className="flex items-center gap-1 font-medium max-w-full">
                <BiBookAdd size={"18px"} /> Add New Record
              </p>
            </Button>
            <section className="flex gap-2">
              <InputField
                placeholder="Search"
                onChange={(e) => setSearch(e?.target?.value)}
              />
              <Button onClick={() => setReload((prev) => !prev)}>Search</Button>
            </section>
          </div>
        </div>
        <div className="flex justify-end w-full mt-5 gap-3">
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => {
              setReload((prev) => !prev);
              setSearch("");
            }}
          >
            <TfiReload size={"20px"} /> Refresh
          </Button>
          <Button className="flex items-center gap-2" onClick={generateReport}>
            <IoMdCloudDownload size={"20px"} /> Download
          </Button>
        </div>
        <div className="border mt-5 px-5 rounded-[8px]">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Date</TableHead>
                <TableHead className="text-center">Amount</TableHead>
                <TableHead className="text-center">
                  TDS Deduction(in %)
                </TableHead>
                <TableHead className="text-center">
                  TDS Deduction(in rupees)
                </TableHead>
                <TableHead className="text-center">Paid Amount</TableHead>
                <TableHead className="text-center">Type</TableHead>
                <TableHead className="text-center">To</TableHead>
                <TableHead className="text-center">Documents</TableHead>
                <TableHead className="text-center">Edit</TableHead>
                <TableHead className="text-center">Delete</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {recordData &&
                recordData?.map((e, i) => (
                  <TableRow>
                    <TableCell className="font-medium text-center">
                      {
                        new Date(e?.recordDate)
                          ?.toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                          })
                          ?.toString()
                          ?.split(",")[0]
                      }
                    </TableCell>
                    <TableCell className="text-center">₹{e?.amount}</TableCell>
                    <TableCell className="text-center">
                      {e?.tdsPercentage}
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.tdsAmount}
                    </TableCell>
                    <TableCell className="text-center">
                      ₹{e?.paidAmount}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.accountType}
                    </TableCell>
                    <TableCell className="text-center">
                      {e?.description}
                    </TableCell>
                    <TableCell className="text-center">
                      <DialogBox
                        title={`${e?.documents?.length || 0} Documents`}
                      >
                        <div className="w-full grid grid-cols-3 gap-5">
                          {e?.documents?.length > 0 ? (
                            e.documents.map((docId) => (
                              <div className="w-[10rem] h-[10rem]">
                                <div className="flex w-full justify-end">
                                  <Button
                                    variant="ghost"
                                    className="absolute bg-white rounded-full w-10 h-10 p-0 mr-1 mt-1 text-red-500 hover:text-red-600 shadow-xl"
                                    onClick={() =>
                                      deleteDocument(e?._id, docId)
                                    }
                                  >
                                    <MdDelete size={"20px"} />
                                  </Button>
                                </div>
                                <Link
                                  to={`${SERVER_URL}/api/v1/file/image/${docId}`}
                                  target="_blank"
                                >
                                  <img
                                    src={`${SERVER_URL}/api/v1/file/image/${docId}`}
                                    className="w-full h-full object-cover"
                                    alt={`Document ${docId}`}
                                    key={docId}
                                  />
                                </Link>
                              </div>
                            ))
                          ) : (
                            <p>No documents available</p> // Optional: Handle case where there are no documents
                          )}
                        </div>
                        {e?.documents?.length < 5 ? (
                          <>
                            <div
                              onClick={() => fileref.current.click()}
                              className="w-full h-[10rem] bg-blue-50/60 rounded-3xl flex justify-center items-center flex-col gap-3 border-2 border-dotted border-gray-300 cursor-pointer"
                            >
                              <input
                                type="file"
                                className="hidden"
                                ref={fileref}
                                onChange={(e) => handleFileChange(e)}
                                multiple
                              />
                              <FiPlus size={"20px"} />
                              <p className="text-[17px]">Upload Documents</p>
                              <p className="text-slate-500">
                                Maximum {5 - Number(e?.documents?.length)}{" "}
                                documents can be uploaded
                              </p>
                              <span className="text-slate-500 text-[15px]">
                                {files?.length} document selected
                              </span>
                            </div>
                            <Button
                              onClick={() =>
                                addFile(e?.documents?.length, e?._id)
                              }
                            >
                              Add
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </DialogBox>
                    </TableCell>
                    <TableCell className="text-center">
                      <Button
                        variant="outline"
                        onClick={() => {
                          setEnableEditRecord(true);
                          setEditData(e);
                          setEditRecordId(e?._id);
                        }}
                      >
                        <CiEdit size={"20px"} />
                      </Button>
                    </TableCell>
                    <TableCell className="font-medium text-center">
                      <Button
                        variant="destructive"
                        onClick={() => deleteRecord(e?._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default AccountBook;
