import Footer from "@/components/Footer";
import Header from "@/components/Header";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

function Layout() {
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  if (!isAuthenticated) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="2xl:ml-[15rem] mt-[4rem] 2xl:mt-0">
        <Outlet />
      </div>
    </>
  );
}

export default Layout;
