import AlertBox from '@/components/AlertBox'
import InputField from '@/components/InputField'
import Loading from '@/components/Loading'
import { Button } from '@/components/ui/button'
import { SERVER_URL } from '@/constant'
import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reloadToggle } from '@/store/reload.slice'
import { login } from '@/store/auth.slice'

function ChangePassword() {
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [message, setMessage] = useState("");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userData = useSelector(state => state?.auth?.userData)

    async function changePassword() {
        if(newPassword !== confirmPassword) {
            setOpen(true)
            setSuccess(false)
            setMessage("Password does not match")
            return null
        }
        let data = {
            password: newPassword
        };
        try {
            setLoading(true);
            const res = await axios({
                url: `${SERVER_URL}/api/v1/user/change/password`,
                method: "post",
                data: data,
                withCredentials: true,
            });
            dispatch(reloadToggle())
            dispatch(login({userData: {...userData, isOnboarded: true}}))
            navigate("/")
        } catch (error) {
            setOpen(true);
            setSuccess(false);
            setMessage("Password update failed");
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <div className="flex h-[100vh] justify-center items-center">
                <Loading color={"bg-sky-500"} />
            </div>
        );
    }

    return (
        <div className='w-full flex justify-center items-center'>
            {success !== undefined && (
                <AlertBox
                    open={open}
                    setOpen={setOpen}
                    success={success}
                    title={message}
                />
            )}
            <div className='w-[90vw] py-5'>
                <h1 className="text-[40px] font-semibold text-center">Change Password</h1>
                <div className='flex justify-center items-center mt-5 flex-col gap-3'>
                    <InputField label="New Password" type="password" onChange={evt => setNewPassword(evt?.target?.value)} />
                    <InputField label="Confirm Password" type="password" onChange={evt => setConfirmPassword(evt?.target?.value)} />
                    <div className='flex justify-center items-center mt-2'>
                        <Button onClick={changePassword}>Save</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword