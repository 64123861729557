import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

function CardComponent({ title, desc, footer, width = "mx-w-[350px]", children }) {
  return (
    <Card className={`${width} shadow-lg border-[0.2px]`}>
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          {desc && <CardDescription>{desc}</CardDescription>}
        </CardHeader>
      )}
      <CardContent className="px-5">{children}</CardContent>
      {footer && <CardFooter className="flex justify-between">{footer}</CardFooter>}
    </Card>
  );
}

export default CardComponent;