import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import InputField from "./InputField";
import { Button } from "./ui/button";
import axios from "axios";
import { SERVER_URL } from "@/constant";
import AlertBox from "./AlertBox";
import { useNavigate } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import { Label } from "./ui/label";
import { DialogClose } from "./ui/dialog";

function AddQuotation({
  setReload,
  gstType,
  invoiceId,
  itemsData,
  setReloadInventory,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [itemName, setItemName] = useState("");
  const [hsnNo, setHsnNo] = useState("");
  const [quantity, setQuantity] = useState(Number);
  const [rate, setRate] = useState(Number);
  const [amount, setAmount] = useState(Number);
  const [gstRate, setGstRate] = useState(Number);
  const [cgst, setCgst] = useState(Number);
  const [sgst, setSgst] = useState(Number);
  const [igst, setIgst] = useState(Number);
  const [total, setTotal] = useState(Number);
  const [unit, setUnit] = useState("pcs");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({
    item: false,
    hsn: false,
    quantity: false,
    rate: false,
    gstRate: false,
  });

  const [enableItemOptions, setEnableItemOptions] = useState(false);
  const [enableInventoryButton, setEnableInventoryButton] = useState(false);

  const items = itemsData?.map((e) => e?.name);

  async function addItem() {
    if (itemName?.length === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          item: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          item: false,
        };
      });
    }
    if (hsnNo?.length === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          hsn: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          hsn: false,
        };
      });
    }
    if (quantity === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          quantity: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          quantity: false,
        };
      });
    }
    if (rate === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          rate: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          rate: false,
        };
      });
    }
    if (gstRate === 0) {
      setErrors((prev) => {
        return {
          ...prev,
          gstRate: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          gstRate: false,
        };
      });
    }
    if (invoiceId?.length === 0) {
      setOpen(true);
      setSuccess(false);
      setMessage("Please click on save and continue first");
      return null;
    }

    let data = {};

    if (gstType === "IGST") {
      data = {
        itemName,
        hsnNo,
        quantity,
        unit,
        rate,
        amount,
        gstRate,
        igst,
        total,
        description,
      };
    } else if (gstType === "CGST & SGST") {
      data = {
        itemName,
        hsnNo,
        quantity,
        unit,
        rate,
        amount,
        gstRate,
        cgst,
        sgst,
        total,
        description,
      };
    } else {
      data = {
        itemName,
        hsnNo,
        quantity,
        unit,
        rate,
        amount,
        total,
        description,
      };
    }
    //console.log(data);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/invoice/item/add`,
        method: "post",
        data: {
          id: invoiceId,
          item: data,
        },
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Add failed");
      console.log(error);
    } finally {
      setLoading(false);
      setDialogOpen(false);
    }
  }

  useEffect(() => {
    setAmount(Math.ceil(Number(rate * quantity)));
  }, [rate, quantity]);

  const filteredData = itemsData.filter((item) => {
    return new RegExp(itemName, "i").test(item?.name);
  });

  useEffect(() => {
    if (gstType === "IGST") {
      setIgst(Math.ceil(Number(amount * (gstRate / 100))));
    } else {
      setCgst(Math.ceil(Number((amount * (gstRate / 100)) / 2)));
      setSgst(Math.ceil(Number((amount * (gstRate / 100)) / 2)));
    }
  }, [gstRate, rate, amount]);

  useEffect(() => {
    setTotal(Math.ceil(Number(amount + amount * (gstRate / 100))));
  }, [gstRate, amount, rate]);

  function handleInventoryItemClick(data) {
    //console.log(data);
    setItemName(data?.name);
    setHsnNo(data?.hsnNo);
    setRate(data?.rate);
    setDescription(data?.description);
    setEnableInventoryButton(false);
    if(gstType !== "none") {
      setGstRate(data?.gstRate);
    } else {
      setGstRate(0)
    }
  }

  async function addInInventory() {
    let data = {
      name: itemName,
      hsnNo: hsnNo,
      rate: rate,
      gstRate: gstRate,
      description: description,
    };

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_URL}/api/v1/inventory/add`,
        method: "post",
        data: data,
        withCredentials: true,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setMessage("Item added to inventory successfully");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setMessage("Item add to inventory failed");
      console.log(error);
    } finally {
      setLoading(false);
      setReloadInventory((prev) => !prev);
    }
  }

  function handleItemChange(event) {
    setItemName(event?.target?.value);
    setEnableItemOptions(true);
    setEnableInventoryButton(true);
    setHsnNo("");
    setGstRate(0);
    setRate(0);
  }

  console.log(filteredData);

  if (gstType === "none") {
    return (
      <>
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          title={
            <p className="flex items-center gap-[7px] font-medium w-full justify-center">
              <FiFilePlus size={"18px"} className="text-sky-600" />
              Add Item
            </p>
          }
          buttonClass={"text-center py-5 border-dashed border-slate-700"}
          desc={"Add A New Item In The Quotation"}
          contentTitle={
            <p className="text-center w-full font-medium">Add Item</p>
          }
        >
          <div
            className="w-full grid grid-cols-2 gap-3"
            onClick={() => setEnableItemOptions(false)}
          >
            <div className="w-full">
              <InputField
                label="Item Name"
                onChange={(e) => handleItemChange(e)}
                value={itemName}
                className={`${
                  errors?.item === true ? "border-red-500 border" : ""
                }`}
              />
              <ul
                className={`border border-slate-200 shadow-lg absolute w-[14rem] bg-white transition-all duration-300 transform ${
                  enableItemOptions === true
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-[-10px] pointer-events-none"
                }`}
              >
                {filteredData?.map((e, i) => (
                  <li
                    className="px-3 py-2 hover:bg-slate-200/70 cursor-pointer font-medium text-[15px]"
                    key={i}
                    onClick={() => handleInventoryItemClick(e)}
                  >
                    {e?.name}
                  </li>
                ))}
              </ul>
            </div>
            <InputField
              label="Quantity"
              type="number"
              className={`${
                errors?.quantity === true ? "border-red-500 border" : ""
              }`}
              onChange={(e) =>
                setQuantity(() => {
                  if (e?.target?.value >= 1) {
                    return e?.target?.value;
                  } else {
                    return 0;
                  }
                })
              }
            />
            <InputField
              label="Rate"
              value={rate}
              onChange={(e) => setRate(e?.target?.value)}
              className={`${
                errors?.rate === true ? "border-red-500 border" : ""
              }`}
            />
            <InputField label="Amount" readOnly value={amount} />
            <InputField label="Total" readOnly value={total} />
            <section className="flex flex-col gap-[0.725rem]">
              <Label>
                Select Unit{" "}
                {/* {productData?.consultMode && (
                    <span className="text-slate-500">
                      (Prev: {productData?.consultMode})
                    </span>
                  )} */}
              </Label>
              <select
                className="h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                onChange={(e) => setUnit(e?.target?.value)}
              >
                <option value={"pcs"}>Pcs</option>
                <option value={"gram"}>Gram</option>
                <option value={"kg"}>KG</option>
                <option value={"litre"}>Litre</option>
                <option value={"box"}>Box</option>
                <option value={"service"}>Service</option>
              </select>
            </section>
          </div>
          <div className="w-full space-y-2">
            <Label>Description</Label>
            <textarea
              className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
              onChange={(e) => setDescription(e?.target?.value)}
              defaultValue={description}
            ></textarea>
          </div>
          {enableInventoryButton === true ? (
            <div className="flex justify-center items-center gap-5 w-full">
              <Button onClick={addItem} className="mt-5">
                Add To Invoice
              </Button>
              {enableInventoryButton === true ? (
                <Button onClick={addInInventory} className="mt-5">
                  Add To Inventory
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            <DialogClose>
              <Button onClick={addItem} className="mt-5">
                Add To Invoice
              </Button>
            </DialogClose>
          )}
        </DialogBox>
      </>
    );
  }

  if (gstType === "IGST") {
    return (
      <>
        {success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        {!success && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <DialogBox
          title={
            <p className="flex items-center gap-[7px] font-medium w-full justify-center">
              <FiFilePlus size={"18px"} className="text-sky-600" />
              Add Item
            </p>
          }
          buttonClass={"text-center py-5 border-dashed border-slate-700"}
          desc={"Add A New Item In The Quotation"}
          contentTitle={
            <p className="text-center w-full font-medium">Add Item</p>
          }
          width="70vw"
        >
          <div
            className="w-full grid grid-cols-2 gap-3"
            onClick={() => setEnableItemOptions(false)}
          >
            <div className="w-full">
              <InputField
                label="Item Name"
                onChange={(e) => handleItemChange(e)}
                value={itemName}
                className={`${
                  errors?.item === true ? "border-red-500 border" : ""
                }`}
              />
              <ul
                className={`border border-slate-200 shadow-lg absolute w-[14rem] bg-white transition-all duration-300 transform ${
                  enableItemOptions === true
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-[-10px] pointer-events-none"
                }`}
              >
                {filteredData?.map((e, i) => (
                  <li
                    className="px-3 py-2 hover:bg-slate-200/70 cursor-pointer font-medium text-[15px]"
                    key={i}
                    onClick={() => handleInventoryItemClick(e)}
                  >
                    {e?.name}
                  </li>
                ))}
              </ul>
            </div>
            {/* <InputField
              label="HSN/SAC"
              onChange={(e) => setHsnNo(e?.target?.value)}
              className={`${
                errors?.hsn === true ? "border-red-500 border" : ""
              }`}
            /> */}
            <InputField
              label="Quantity"
              type="number"
              className={`${
                errors?.quantity === true ? "border-red-500 border" : ""
              }`}
              onChange={(e) =>
                setQuantity(() => {
                  if (e?.target?.value >= 1) {
                    return e?.target?.value;
                  } else {
                    return 0;
                  }
                })
              }
            />
            <InputField
              label="Rate"
              value={rate}
              onChange={(e) => setRate(e?.target?.value)}
              className={`${
                errors?.rate === true ? "border-red-500 border" : ""
              }`}
            />
            <InputField label="Amount" readOnly value={amount} />
            <InputField
              label="GST Rate"
              value={gstRate}
              onChange={(e) => setGstRate(Number(e?.target?.value))}
              className={`${
                errors?.gstRate === true ? "border-red-500 border" : ""
              }`}
            />
            <InputField label="IGST" readOnly value={igst} />
            <InputField label="Total" readOnly value={total} />
            <section className="flex flex-col gap-[0.725rem]">
              <Label>
                Select Unit{" "}
                {/* {productData?.consultMode && (
                    <span className="text-slate-500">
                      (Prev: {productData?.consultMode})
                    </span>
                  )} */}
              </Label>
              <select
                className="h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
                defaultValue={""}
                onChange={(e) => setUnit(e?.target?.value)}
              >
                <option value={"pcs"}>Pcs</option>
                <option value={"gram"}>Gram</option>
                <option value={"kg"}>KG</option>
                <option value={"litre"}>Litre</option>
                <option value={"box"}>Box</option>
                <option value={"service"}>Service</option>
              </select>
            </section>
          </div>
          <div className="w-full space-y-2">
            <Label>Description</Label>
            <textarea
              className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
              onChange={(e) => setDescription(e?.target?.value)}
            ></textarea>
          </div>
          {enableInventoryButton === true ? (
            <div className="flex justify-center items-center gap-5 w-full">
              <Button onClick={addItem} className="mt-5">
                Add To Invoice
              </Button>
              {enableInventoryButton === true ? (
                <Button onClick={addInInventory} className="mt-5">
                  Add To Inventory
                </Button>
              ) : (
                ""
              )}
            </div>
          ) : (
            <DialogClose>
              <Button onClick={addItem} className="mt-5">
                Add To Invoice
              </Button>
            </DialogClose>
          )}
        </DialogBox>
      </>
    );
  }

  return (
    <>
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={message}
        />
      )}
      <DialogBox
        title={
          <p className="flex items-center gap-[7px] font-medium">
            <FiFilePlus size={"18px"} className="text-sky-600" />
            Add Item
          </p>
        }
        buttonClass={"text-center py-5 border-dashed border-slate-700"}
        desc={"Add A New Item In The Invoice"}
        contentTitle={
          <p className="text-center w-full font-medium">Add Item</p>
        }
      >
        <div
          className="w-full grid grid-cols-2 gap-3"
          onClick={() => setEnableItemOptions(false)}
        >
          <div className="w-full">
            <InputField
              label="Item Name"
              onChange={(e) => handleItemChange(e)}
              value={itemName}
              className={`${
                errors?.item === true ? "border-red-500 border" : ""
              }`}
            />
            <ul
              className={`border border-slate-200 shadow-lg absolute w-[14rem] bg-white transition-all duration-300 transform ${
                enableItemOptions === true
                  ? "opacity-100 translate-y-0"
                  : "opacity-0 translate-y-[-10px] pointer-events-none"
              }`}
            >
              {filteredData?.map((e, i) => (
                <li
                  className="px-3 py-2 hover:bg-slate-200/70 cursor-pointer font-medium text-[15px]"
                  key={i}
                  onClick={() => handleInventoryItemClick(e)}
                >
                  {e?.name}
                </li>
              ))}
            </ul>
          </div>
          {/* <InputField
            label="HSN/SAC"
            onChange={(e) => setHsnNo(e?.target?.value)}
            className={`${errors?.hsn === true ? "border-red-500 border" : ""}`}
          /> */}
          <InputField
            label="Quantity"
            type="number"
            className={`${
              errors?.quantity === true ? "border-red-500 border" : ""
            }`}
            onChange={(e) =>
              setQuantity(() => {
                if (e?.target?.value >= 1) {
                  return e?.target?.value;
                } else {
                  return 0;
                }
              })
            }
          />
          <InputField
            label="Rate"
            value={rate}
            onChange={(e) => setRate(e?.target?.value)}
            className={`${
              errors?.rate === true ? "border-red-500 border" : ""
            }`}
          />
          <InputField label="Amount" readOnly value={amount} />
          <InputField
            label="GST Rate"
            value={gstRate}
            onChange={(e) => setGstRate(Number(e?.target?.value))}
            className={`${
              errors?.gstRate === true ? "border-red-500 border" : ""
            }`}
          />
          <InputField label="CGST" readOnly value={cgst} />
          <InputField label="SGST" readOnly value={sgst} />
          <InputField label="Total" readOnly value={total} />
          <div className="flex flex-col gap-[0.725rem] ">
            <Label>
              Select Unit{" "}
              {/* {productData?.consultMode && (
                <span className="text-slate-500">
                  (Prev: {productData?.consultMode})
                </span>
              )} */}
            </Label>
            <select
              className="max-w-[24rem] h-[2.3rem] p-[0.37rem] rounded-md bg-transparent border"
              defaultValue={""}
              onChange={(e) => setUnit(e?.target?.value)}
            >
              <option value={"pcs"}>Pcs</option>
              <option value={"gram"}>Gram</option>
              <option value={"kg"}>KG</option>
              <option value={"litre"}>Litre</option>
              <option value={"box"}>Box</option>
              <option value={"service"}>Service</option>
            </select>
          </div>
        </div>
        <div className="w-full space-y-2">
          <Label>Description</Label>
          <textarea
            className="w-full focus:outline-none focus:border-slate-500 border p-3 rounded-[5px] h-[7rem]"
            onChange={(e) => setDescription(e?.target?.value)}
            defaultValue={description}
          ></textarea>
        </div>
        {enableInventoryButton === true ? (
          <div className="flex justify-center items-center gap-5 w-full">
            <Button onClick={addItem} className="mt-5">
              Add To Invoice
            </Button>
            {enableInventoryButton === true ? (
              <Button onClick={addInInventory} className="mt-5">
                Add To Inventory
              </Button>
            ) : (
              ""
            )}
          </div>
        ) : (
          <DialogClose>
            <Button onClick={addItem} className="mt-5">
              Add To Invoice
            </Button>
          </DialogClose>
        )}
      </DialogBox>
    </>
  );
}
export default AddQuotation;
