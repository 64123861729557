import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reloadToggle : false
}

const reduxReload = createSlice({
    name: "reduxReload",
    initialState,
    reducers: {
        reloadToggle: (state) => {
            state.reloadToggle = !state.reloadToggle
        }
    }
})

export const {reloadToggle} = reduxReload.actions
export default reduxReload.reducer